import { concat } from "@ember/helper";
import emoji from "discourse/helpers/emoji";
import escape from "discourse/lib/escape";
import { i18n } from "discourse-i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const title = (description, endsAt, timezone) => {
  let content = escape(description);
  if (endsAt) {
    const until = moment.tz(endsAt, timezone).format(i18n("dates.long_date_without_year"));
    content += `\n${i18n("until")} ${until}`;
  }
  return content;
};
const UserStatusBubble = setComponentTemplate(createTemplateFactory(
/*
  
  <div class="user-status-background">
    {{emoji
      @status.emoji
      title=(title @status.description @status.ends_at @timezone)
      alt=(concat ":" @status.emoji ":")
    }}
  </div>

*/
{
  "id": "BLM57qpZ",
  "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"user-status-background\"],[12],[1,\"\\n    \"],[1,[28,[32,0],[[30,1,[\"emoji\"]]],[[\"title\",\"alt\"],[[28,[32,1],[[30,1,[\"description\"]],[30,1,[\"ends_at\"]],[30,2]],null],[28,[32,2],[\":\",[30,1,[\"emoji\"]],\":\"],null]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@status\",\"@timezone\"],false,[]]",
  "moduleName": "/var/deploy/forum/web_head/releases/20250213174130/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/header/user-dropdown/user-status-bubble.js",
  "scope": () => [emoji, title, concat],
  "isStrictMode": true
}), templateOnly(undefined, "user-status-bubble:UserStatusBubble"));
export default UserStatusBubble;